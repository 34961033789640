
import {
    ChangeTableEvent,
    Emitter,
    PropType,
    TableLimit,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import betGroup from '@/components/game/BetGroup.vue'
import prediction from '@/components/game/Prediction.vue'
import MPrediction from '@/components/game/MPrediction.vue'
import ChipsControl from '@/components/game/ChipsControl.vue'
import {
    iconVideo,
    iconSound,
    iconArrowLeft,
    iconArrowDown
} from '@/components/icon'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { ACT, EventBuss, GameRoundState, OtherApiParams } from '@/types/global'
import roadMap from '@/components/roadmap/index.vue'
import {
    GAMETYPE,
    RoadMapSummaryData
} from '@/components/roadmap/datamodel/types'
import { RoadMapData } from '@/components/roadmap/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/roadmap/utils'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { Desk } from '@/types/Desk'
import { AppActions, AppMutation } from '@/store/types'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { AxiosSource } from '@/api'
import axios, { CancelTokenSource } from 'axios'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import dCardResult from '@/components/game/d-card-result.vue'
import mcardResult from '@/components/game/m-card-result.vue'
import { Dictionary } from '@/types/dictionary'
import { GameResult } from '@/types/GameResult'
import { getValueByKey } from '@/utils/api'
import { VTextMarquee } from 'vue-text-marquee'
import { isIpadPro } from '@/utils'

export default defineComponent({
    emits: ['show-select-table', 'shoe-ju', 'enter-table', 'show-limit'],
    components: {
        'icon-down': iconArrowDown,
        'bet-group': betGroup,
        'prediction': prediction,
        'm-prediction': MPrediction,
        // 'icon-setting': iconSetting,
        'icon-video': iconVideo,
        'icon-sound': iconSound,
        // 'icon-zoom': iconZoom,
        'icon-arrow-lelft': iconArrowLeft,
        'chips-control': ChipsControl,
        'road-map': roadMap,
        // 'timer-countdownn': timerCountdownn,
        'card-result': dCardResult,
        'm-card-result': mcardResult,
        'marquee-text': VTextMarquee
    },
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        gameType: {
            default: -1,
            type: Number as PropType<number>
        },
        xian: {
            default: -1,
            type: Number as PropType<number>
        }
    },
    setup(props, { emit }) {
        const store = useStore()
        const router = useRouter()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const showMediaTools = ref(true)
        const videoIsOn = ref(true)

        const pcMapView = ref()

        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const roadMapData = ref<RoadMapData>(parseRoadMap(''))
        const pcMapKey = ref(`pc-map-key-${new Date().getTime()}`)

        const deskData = ref<Desk>()
        const allowBet = ref(false)
        const hasUnconfimerBet = ref<boolean>(false)
        const betView = ref()

        let serviceTimeOut: boolean | number | any = false
        let failureCounter = 0
        const PULL_INTERVAL = 3000
        const PULL_CARD_INTERVAL = 1500
        const winner = ref<number[]>([])

        let countDownInterval: number | boolean | any = false
        const currentCount = ref<number>(-1)

        const isVideoOn = ref<boolean>(true)
        const isLoadingVideo = ref(true)
        const videoZoom = ref(false)
        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any

        const showCardDisplay = ref(false)

        let currentGameId: string | undefined = undefined
        let needRefreshGameResult = true
        let currentGameResult: GameResult | any = undefined

        let showCardTimeout: boolean | number | any = false
        let cardRequestTimeout: number | boolean = false

        let videos: string[] = []
        let videoRequestFailure = 0
        let videoRequestTimeOut: boolean | number | any = false
        let videoAxiosSource: CancelTokenSource

        const winOrLostAmount = ref<number>(0)
        const tempTotalBet = ref<number>(0)

        onMounted(() => {
            // emit('shoe-ju', '83928492849')
            loadData()
            isVideoOn.value = canPlayVideo.value

            // get video info here
            if (isVideoOn.value) getVideoInfos()
        })
        onBeforeMount(() => {
            // setup listeners
            emitter.on(EventBuss.WINDOW_RESIZED, onWindowResize)
            // listen to page onActive and in active state
            emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
        })

        onBeforeUnmount(() => {
            // remove listeners
            emitter.off(EventBuss.WINDOW_RESIZED, onWindowResize)

            emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            // dispose video
            disposeVideoPlayer()
            stopCountDown()
            stopPullingData()
            stopRequestingCard()
            stopRequestingVideoInfos()
            showCardDisplay.value = false
        })

        const handleOnPageActive = () => {
            // cancel request video info if have pending request

            // 如果视频已经加载并正在播放，不需要重新加载
            if (nodePlayer && !isLoadingVideo.value) {
                // 视频已经加载并播放，直接返回
                return
            }

            stopRequestingVideoInfos()
            // request new video info
            getVideoInfos()
        }

        const onWindowResize = () => {
            pcMapKey.value = `pc-map-key-${new Date().getTime()}`
        }

        const loadData = (_forceReload = false) => {
            if (props.desk <= 0 || props.xian <= 0 || props.gameType <= 0) {
                return
            }

            const _param = {
                ...OtherApiParams,
                act: ACT.SINGLE_DESK_INFO,
                desk: props.desk,
                username: user.value,
                sessionID: token.value
            }

            if (!_forceReload) {
                store.commit(AppMutation.SHOW_LOADING)
            }

            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((response: string) => {
                    failureCounter = 0

                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    if (response.includes('err=')) handleOnSessionExpired()
                    else {
                        deskData.value = Desk.Parse(
                            response.split('&postionId')[0]
                        )

                        emit(
                            'shoe-ju',
                            t('game.shoeAndJu', [
                                deskData.value.gameSet,
                                deskData.value.ju
                            ])
                        )

                        if (ingameNotice.value !== deskData.value.notice)
                            store.commit(
                                AppMutation.SET_IN_GAME_NOTICE,
                                deskData.value.notice
                            )
                        store.commit(
                            AppMutation.SET_COIN,
                            deskData.value.balance
                        )

                        // if (
                        //     deskData.value.currentTime < 0 &&
                        //     deskData.value.status !== 0
                        // )

                        if (
                            deskData.value.currentTime < 0 &&
                            deskData.value.status !==
                                GameRoundState.END_OF_ROUND
                        ) {
                            cardRequestTimeout = setTimeout(() => {
                                OnrecievedDeskResult()
                            }, PULL_INTERVAL)
                        } else {
                            serviceTimeOut = setTimeout(() => {
                                loadData(true)
                            }, PULL_INTERVAL)
                        }
                    }
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        failureCounter += 1

                        if (!_forceReload) {
                            store.commit(AppMutation.HIDE_LOADING)
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                        }

                        if (failureCounter > 3) {
                            // stop pulling service
                            stopPullingData()
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                            store.commit(AppMutation.CLEAR_ALL)
                            router.push({ name: ROUTES.LOGIN })
                            return
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
        }

        const handleShowGameResult = (e: number[]) => {
            winner.value = e
        }

        const OnrecievedDeskResult = () => {
            if (
                currentGameId === undefined ||
                resultCache.value.ContainsKey(currentGameId)
            ) {
                if (currentGameId !== deskData.value?.gameId) {
                    currentGameId = deskData.value?.gameId
                }
            }

            if ((deskData.value?.currentTime as number) <= 0) {
                needRefreshGameResult = true
            }

            if (
                needRefreshGameResult &&
                currentGameId !== undefined &&
                !resultCache.value.ContainsKey(currentGameId) &&
                currentCount.value <= 0
            ) {
                // get the card

                const _params = {
                    ...OtherApiParams,
                    act: ACT.CARD_INFO,
                    username: user.value,
                    gameId: currentGameId
                }

                store
                    .dispatch(AppActions.FETCH_DATA, _params)
                    .then((_data: string) => {
                        const err = getValueByKey(_data, 'err')
                        if (err) {
                            handleOnSessionExpired()
                        } else {
                            if (currentGameResult !== undefined) {
                                if (currentGameResult.rawData === _data) {
                                    cardRequestTimeout = setTimeout(() => {
                                        OnrecievedDeskResult()
                                    }, PULL_CARD_INTERVAL)
                                    return
                                }
                            }

                            if (
                                currentGameId !== undefined &&
                                resultCache.value.ContainsKey(currentGameId)
                            ) {
                                cardRequestTimeout = setTimeout(() => {
                                    OnrecievedDeskResult()
                                }, PULL_CARD_INTERVAL)
                                return
                            }

                            currentGameResult = new GameResult(
                                _data,
                                (deskData.value as Desk).gameType
                            )

                            if (
                                currentGameResult.state ===
                                    GameRoundState.END_OF_ROUND &&
                                !currentGameResult.isNewShoe
                            ) {
                                store.commit(AppMutation.ADD_RESULT_TO_CACHE, {
                                    key: currentGameId,
                                    value: currentGameResult.rawData
                                })

                                needRefreshGameResult = true

                                winOrLostAmount.value = currentGameResult.won
                                const _winOrLostAmount = currentGameResult.won

                                if (_winOrLostAmount !== 0) {
                                    if (_winOrLostAmount !== 0) {
                                        const resultAmount =
                                            _winOrLostAmount > 0
                                                ? `+${_winOrLostAmount}`
                                                : _winOrLostAmount
                                        if (betView.value)
                                            betView.value.showErrorMessage(
                                                resultAmount
                                            )
                                    }
                                }

                                if (typeof showCardTimeout === 'number') {
                                    clearTimeout(showCardTimeout)
                                    showCardTimeout = false
                                    // showCardResult.value = false
                                    showCardDisplay.value = false
                                }

                                if (currentGameResult.hasCardToDisplay) {
                                    showCardDisplay.value = true
                                    const { whoWin, bluePair, redpair } =
                                        currentGameResult as GameResult

                                    if (
                                        deskData.value &&
                                        deskData.value.gameType === 1
                                    ) {
                                        if (whoWin === 1) {
                                            // banker
                                            if (bluePair && redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.BANKER_WIN_BOTH_PAIR
                                                )
                                            else if (bluePair && !redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.BANKER_WIN_PLAYER_PAIR
                                                )
                                            else if (redpair && !bluePair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.BANKER_WIN_BANKER_PAIR
                                                )
                                            else
                                                audioPlayer.Play(
                                                    SOUNDKEY.BANKER_WIN
                                                )
                                        } else if (whoWin === 3) {
                                            // player
                                            if (bluePair && redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.PLAYER_WIN_BOTH_PAIR
                                                )
                                            else if (bluePair && !redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.PLAYER_WIN_PLAYER_PAIR
                                                )
                                            else if (redpair && !bluePair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.PLAYER_WIN_BANKER_PAIR
                                                )
                                            else
                                                audioPlayer.Play(
                                                    SOUNDKEY.PLAYER_WIN
                                                )
                                        } else if (whoWin === 2) {
                                            // tie
                                            if (bluePair && redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.TIE_BOTH_PAIR
                                                )
                                            else if (bluePair && !redpair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.TIE_PLAYER_PAIR
                                                )
                                            else if (redpair && !bluePair)
                                                audioPlayer.Play(
                                                    SOUNDKEY.TIE_BANKER_PAIR
                                                )
                                            else audioPlayer.Play(SOUNDKEY.TIE)
                                        }
                                    } else if (
                                        deskData.value &&
                                        deskData.value.gameType === 2
                                    ) {
                                        if (whoWin === 6)
                                            audioPlayer.Play(SOUNDKEY.TIGER_WIN)
                                        else if (whoWin === 4)
                                            audioPlayer.Play(
                                                SOUNDKEY.DRAGON_WIN
                                            )
                                        else if (whoWin === 5) {
                                            audioPlayer.Play(SOUNDKEY.TIE)
                                        }
                                    }
                                } else {
                                    // showCardResult.value = true
                                }

                                showCardTimeout = setTimeout(() => {
                                    // showCardResult.value = false
                                    showCardDisplay.value = false
                                    // hide game result
                                    winOrLostAmount.value = 0
                                    winner.value = []
                                }, 6000)

                                setTimeout(() => {
                                    emitter.emit(
                                        EventBuss.SHOW_GAME_RESULT,
                                        currentGameResult
                                    )
                                }, 10)

                                stopRequestingCard()
                                loadData(true)
                            } else {
                                cardRequestTimeout = setTimeout(() => {
                                    OnrecievedDeskResult()
                                }, PULL_CARD_INTERVAL)
                            }
                        }
                    })
                    .catch((e: any) => {
                        if (!axios.isCancel(e)) {
                            failureCounter += 1

                            if (failureCounter > 3) {
                                stopRequestingCard()
                                stopPullingData()
                                emitter.emit(EventBuss.TOAST_MESSAGE, e)
                                store.commit(AppMutation.CLEAR_ALL)
                                router.push({ name: ROUTES.LOGIN })
                                return
                            }

                            cardRequestTimeout = setTimeout(() => {
                                OnrecievedDeskResult()
                            }, PULL_CARD_INTERVAL)
                        }
                    })
            } else {
                stopRequestingCard()
                loadData(true)
            }
        }

        const stopPullingData = () => {
            if (AxiosSource) {
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }

            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const stopRequestingVideoInfos = () => {
            if (typeof videoAxiosSource !== 'undefined') {
                videoAxiosSource.cancel()
                videoAxiosSource = axios.CancelToken.source()
            }

            if (typeof videoRequestTimeOut === 'number') {
                clearTimeout(videoRequestTimeOut)
                videoRequestTimeOut = false
            }
        }

        const stopRequestingCard = () => {
            if (AxiosSource) {
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }

            if (typeof cardRequestTimeout === 'number') {
                clearTimeout(cardRequestTimeout)
                cardRequestTimeout = false
            }
        }

        const handleOnSessionExpired = () => {
            // console.log('End Session')
            stopPullingData()
            stopRequestingCard()
            stopCountDown()
            disposeVideoPlayer()
            emitter.emit(EventBuss.TOAST_MESSAGE, t('prompt.sessionexpired'))
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.SERVER_SELECTION })
        }

        const showDialog = (dialogName: DialogBoxName) => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: dialogName
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const onRoadMapDataParsed = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            roadMapData.value = a
            nextRedRoadMapSummaryData.value = { data: b }
            nextBlueRoadMapSummaryData.value = { data: c }
        }
        const handleSwitchTable = (e: {
            desk: number
            gameType: number
            xian: number
        }) => {
            // stop count down
            stopCountDown()
            // stop video
            disposeVideoPlayer()
            // stoppulling data
            stopPullingData()
            // stop cardPulling
            stopRequestingCard()
            stopRequestingVideoInfos()
            // hideShowing cards
            showCardDisplay.value = false
            currentGameId = undefined
            winner.value = []
            winOrLostAmount.value = 0

            allowBet.value = false
            hasUnconfimerBet.value = false
            // start loading data again
            loadData()
            getVideoInfos()
        }

        // #region Click events

        const onSetVideo = () => {
            if (videoIsOn.value) {
                // videoIsOn = !videoIsOn
                // isVideoOn.value = false
                videoIsOn.value = false
                disposeVideoPlayer()
            } else {
                // isVideoOn.value = true
                videoIsOn.value = true
                initVideoPlayer()
            }
        }
        const onSoundClicked = () => {
            // soundIsOn = !soundIsOn
            const _isSoundOn = soundIsOn.value ? 0 : 1
            store.commit(AppMutation.SET_SOUND, _isSoundOn)
            if (audioPlayer) audioPlayer.isOn = _isSoundOn
        }

        const confirmBet = () => {
            const view = betView.value as any
            if (view) view.confirmBet()
        }
        const cancelBet = () => {
            if (hasUnconfimerBet.value) {
                if (typeof betView.value !== 'undefined') {
                    const view = betView.value as any
                    if (view) view.resetBet()
                }
            }
        }

        const enterTable = (i: number, _gameType: number) => {
            const ev: ChangeTableEvent = {
                desk: i,
                gameType: _gameType,
                xian: props.xian
            }
            emit('enter-table', ev)
        }

        // #endregion

        const predict = (i: number) => {
            if (pcMapView.value) {
                pcMapView.value.Predict(i)
            }
        }

        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                // allow betting
                allowBet.value = true
                currentCount.value = deskData.value?.currentTime ?? 0
                if (currentCount.value > 20) {
                    audioPlayer.Play(SOUNDKEY.START_BET)
                }

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                        audioPlayer.Play(SOUNDKEY.STOP_BET)
                    } else {
                        if (currentCount.value <= 11 && currentCount.value > 0)
                            audioPlayer.Play(SOUNDKEY.TICK)
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const stopCountDown = () => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
                allowBet.value = false
                if (typeof betView.value !== 'undefined') {
                    const view = betView.value as any
                    if (view) view.resetBet()
                }
            }
        }

        const handleTempBetUpdated = (_isTrue: boolean) => {
            hasUnconfimerBet.value = _isTrue
        }

        const getVideoInfos = () => {
            // set video infos to empty
            videos = []
            // close all videos that is playing
            disposeVideoPlayer()

            // set token
            if (typeof videoAxiosSource === 'undefined') {
                videoAxiosSource = axios.CancelToken.source()
            }

            const _params = {
                ...OtherApiParams,
                act: ACT.VIDEO_INFOS,
                sessionID: token.value,
                desk: props.desk,
                username: user.value
            }

            store
                .dispatch(AppActions.FETCH_VIDEOS, {
                    params: _params,
                    token: videoAxiosSource
                })
                .then((response: string) => {
                    videoRequestFailure = 0
                    if (response.includes('err=')) handleOnSessionExpired()
                    else {
                        videos = response.split('#')
                        initVideoPlayer()
                    }
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        // request video info again
                        videoRequestFailure += 1
                        if (videoRequestFailure > 3) {
                            // stop pulling service
                            stopRequestingVideoInfos()
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                            store.commit(AppMutation.CLEAR_ALL)
                            router.push({ name: ROUTES.LOGIN })
                            return
                        }
                        serviceTimeOut = setTimeout(() => {
                            getVideoInfos()
                        }, PULL_INTERVAL)
                    }
                })
        }

        // #region VideoPlayer

        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()
            if (!videoIsOn.value) return
            let isVideoConnected = false

            videos.forEach((vPath, index) => {
                // eslint-disable-next-line no-undef
                let _player: NodePlayer | any = new NodePlayer()
                _player.setVolume(0.0)
                _player.setScaleMode(2)
                _player.setView('videoplayer2')
                _player.setBufferTime(1000)
                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        _player.setView('videoplayer1')

                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                }
                            }
                        )
                        nodePlayer.on('error', (e: any) => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.stop()
                                nodePlayer.clearView()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.stop()
                        _player.clearView()
                        _player = null
                    }
                })
                _player.on('error', (e: any) => {
                    // console.log(e, 'jjk')
                })
                videoPlayerCollection.push(_player)
                _player.start(vPath)
            })
        }

        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }
            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.stop()
                    _player.clearView()
                    _player = null
                }
            })

            videoPlayerCollection = []
        }

        // #endregion

        const tableName = computed(() => {
            if (deskData.value && deskData.value.gameType === 1) {
                return `${t('baccarat.name')} F${props.desk}`
            } else if (deskData.value && deskData.value.gameType === 2) {
                return `${t('dragontiger.name')} F${props.desk}`
            }

            return ''
        })

        const timerColor = computed((): string => {
            if (currentCount.value > 20) return 'green'
            else if (currentCount.value <= 20 && currentCount.value > 10)
                return 'orange'
            else if (currentCount.value <= 10 && currentCount.value !== -1)
                return 'red'
            return ''
        })

        const redConfirmedBet = computed((): number => {
            if (!deskData.value) return 0
            if (deskData.value.gameType === GAMETYPE.baccarat) {
                return deskData.value.redBet
            } else if (deskData.value.gameType === GAMETYPE.dragonTiger) {
                return deskData.value.blueBet
            }
            return 0
        })
        const blueConfirmedBet = computed((): number => {
            if (!deskData.value) return 0

            if (deskData.value.gameType === GAMETYPE.baccarat) {
                return deskData.value.blueBet
            } else if (deskData.value.gameType === GAMETYPE.dragonTiger) {
                return deskData.value.redBet
            }

            return 0
        })
        const greenConfirmedBet = computed((): number => {
            if (!deskData.value) return 0
            return deskData.value.tieBet
        })
        const redPairConfirmedBet = computed((): number => {
            if (!deskData.value) return 0

            return deskData.value.redPairBet
        })
        const bluePairConfirmedBet = computed((): number => {
            if (!deskData.value) return 0
            return deskData.value.bluePairBet
        })

        const tableLimit = computed(() => {
            if (limit.value) return `${limit.value.Min}-${limit.value.Max}`

            return '0-0'
        })

        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )

        const shoeAndJu = computed(() => {
            let ju = 0
            let shoe = 0
            if (deskData.value) {
                shoe = deskData.value.gameSet
                ju = deskData.value.ju
            }

            return t('game.shoeAndJu', [shoe, ju])
        })

        const totalBet = computed(() => {
            if (!deskData.value) {
                return 0
            } else {
                let tempTotalbet = 0
                const {
                    redBet,
                    redPairBet,
                    bluePairBet,
                    blueBet,
                    tieBet,
                    gameType
                } = deskData.value

                if (gameType === GAMETYPE.baccarat) {
                    tempTotalbet =
                        redBet + redPairBet + blueBet + bluePairBet + tieBet
                } else {
                    tempTotalbet = redBet + blueBet + tieBet
                }

                if (tempTotalbet < tempTotalBet.value) {
                    tempTotalbet = tempTotalBet.value
                }

                return tempTotalbet
            }
        })

        const bacDesks = computed((): number[] => store.getters['bacDesks'])
        const draDesks = computed((): number[] => store.getters['draDesks'])
        const vipDesk = computed((): number[] => store.getters['vipDesk'])

        const limit = computed(
            (): TableLimit | undefined => store.getters['limit']
        )
        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])
        const canPlayVideo = computed((): boolean => store.getters['videoIsOn'])
        const ingameNotice = computed(
            (): string => store.getters['ingameNotice']
        )
        const soundIsOn = computed((): number => store.getters['soundEffect'])

        const resultCache = computed(
            (): Dictionary<string> => store.getters['resultCache']
        )

        const gtype = computed(() => {
            if (deskData.value) return deskData.value.gameType
            else return 1
        })

        const isIpadProDevice = computed((): boolean => isIpadPro())

        watch(
            () => deskData.value,
            (v) => {
                if (v) {
                    if (v.currentTime !== -1) {
                        // start time
                        startCountDown()
                    } else {
                        if (typeof countDownInterval === 'number') {
                            // stop count down
                            stopCountDown()
                            audioPlayer.Play(SOUNDKEY.STOP_BET)
                        }

                        currentCount.value = -1
                    }
                }
            },
            { immediate: true }
        )

        watch(
            () => [props.desk, props.gameType, props.xian],
            ([d, g, x]) => {
                handleSwitchTable({
                    desk: d,
                    gameType: g,
                    xian: x
                })
            }
        )

        return {
            isIpadProDevice,
            gtype,
            tableName,
            timerColor,
            enterTable,
            winOrLostAmount,
            totalBet,
            limit,
            bacDesks,
            draDesks,
            vipDesk,
            ingameNotice,
            shoeAndJu,
            tableLimit,
            winner,
            betView,
            allowBet,
            hasUnconfimerBet,
            redConfirmedBet,
            blueConfirmedBet,
            greenConfirmedBet,
            redPairConfirmedBet,
            bluePairConfirmedBet,
            showCardDisplay,
            user,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,
            showMediaTools,
            videoIsOn,
            soundIsOn,
            pcMapView,
            pcMapKey,
            deviceOrientation,
            currentCount,
            deskData,
            roadMapData,
            isLoadingVideo,
            onSoundClicked,
            onSetVideo,
            cancelBet,
            confirmBet,
            predict,
            showDialog,
            onRoadMapDataParsed,
            t,
            handleTempBetUpdated,
            handleOnSessionExpired,
            handleShowGameResult
        }
    }
})
